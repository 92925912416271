import React, { FunctionComponent, useContext, useRef,useState } from 'react';
import { Link } from 'react-router-dom';
import Footer from '../Footer';
import { StateProviderContext, Hendelse } from '../StateProvider';

export interface Props {
}

const Admin: FunctionComponent<Props> = (props) => {
    const {hentDatabaseData, slettAlleHendelser: slettAlleInnleveringer} = useContext(StateProviderContext);
    const [data,setData] = useState(hentDatabaseData());
    const [rawHTML, setRawHTML] = useState(hentDatabaseData());
    return (<div>
    <article>
        <section className='splash'>
            <section>
                <h4>Dette er hva vi har lagret på kun din nettleser (ikke cookie).</h4>
                
            <textarea rows={60} cols={50} value={data} onChange={(e)=>{
                const txt = e.target.value;
                setData(txt);
                setRawHTML(txt);
            }}></textarea>
            <input type={"button"} value="Oppdater lokal data" onClick={(e)=>{
             localStorage.setItem("data", rawHTML);
             alert("Oppdatert ferdig :) ");
            }}/>
            {" "}
            <input type={"button"} style={{backgroundColor:"red"}} value="Slett alt" onClick={(e)=>{
             const svar = confirm("Slette ALT som er lagret lokalt på din nettleser for jegtenker.no?");
             if(svar){
               slettAlleInnleveringer();
               window.location.href = "/journal";
             }
            }}/>
            
            </section>
        </section>
    </article>
    
    {" "}
     <Link className='button'  to={{
        pathname: "/nytt/steg/1",
      }} ><>&#9997;</> Ny</Link>
      {" "}
      <Link className='button' to={{
            pathname: "/journal",
        }} ><>&#128218;</> Journal</Link>
      <Footer />
    </div>);
};

export default Admin;