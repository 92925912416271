import React, { FunctionComponent, useContext, useState } from 'react';
import { Link } from 'react-router-dom';

export interface Props {
}

const SideIkkeFunnet: FunctionComponent<Props> = (props) => {
    return (<div>
    <article>
        <section className='splash'>
            <section>
            <h1>Ops :(</h1>
            <p>Jeg fant ikke siden du ser etter!</p>
            </section>
        </section>
    </article>
    
     {" "}
     <Link className='button'  to={{
        pathname: "/nytt/steg/1",
      }} ><>&#9997;</> Ny</Link>
      {" "}
    <Link className='button' to={{
        pathname: "/journal",
      }} >Journal</Link>
      {" "}
      <br />
      <p>Prosjektet er laget av frivillige.</p>
      </div>);
};

export default SideIkkeFunnet;