import React, { FunctionComponent, useContext, useState , useEffect, createRef} from 'react';
import { StateProviderContext } from '../../StateProvider';
import { Link } from 'react-router-dom';
import Navigasjon from './Navigasjon';
export interface Props {
    
  }

const Følelser: FunctionComponent<Props> = (props) => {
  const dateRef = createRef<HTMLInputElement>();
  const {lagreDelerAvHendelse: lagreDelerAvInnlevering,enNyHendelse: nyInnlevering} = useContext(StateProviderContext);
  useEffect(() => {
    if(dateRef.current){
      dateRef.current.valueAsDate = new Date();
    }

  }, []);

    return (<div className="new-submition">
      <h4>Hva følte du?</h4>
      <p><em>"Opplevelse av sterk angst og uro, fortvilet, stresset."</em></p>
            <textarea rows={12} cols={30} name="feelings" onChange={(e)=>{
              lagreDelerAvInnlevering({feelings:e.target.value})
            }} value={nyInnlevering?.feelings}></textarea>
            <div className="submitionButtonsWrapper">
            <Navigasjon page={3} pages={6} />
            </div>
    </div>);
}

export default Følelser;