import React, { FunctionComponent, useContext, useState } from 'react';
export interface Props {
    
  }

const Header: FunctionComponent<Props> = (props) => {
    return (<header>
        <a href="/">
              <div className="logo"></div> 
              <h1>Jeg tenker</h1></a>
            </header>);
            }

export default Header;