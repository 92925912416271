

import React, {FunctionComponent, useContext, useState , useEffect, createRef} from 'react';
import { BrowserRouterProps, Link } from 'react-router-dom';
import { StateProviderContext } from '../../StateProvider';
import Footer from '../../Footer';
export interface Props {
    page:number,
    pages: number
  }

const Navigasjon: FunctionComponent<Props> = (props:Props) => {
    const {lagreHendelse: lagreInnlevering} = useContext(StateProviderContext);
    return (<div className="navigation">
            <p className="stepWrapper">Steg: {props.page}/{props.pages}</p>
            {"  "}
            {props.page !== 1 &&
                <Link
                className='button' 
                to={{
                pathname: "/nytt/steg/" + (props.page - 1),
                }}
                >Tilbake</Link>
            }
             {"  "}
             {props.page === props.pages &&
                <input  
                    type="button"
                    value="Lagre"
                    onClick={(e)=> {
                        lagreInnlevering()
                        window.location.href = "/journal";
                    }}
                />
             }
              {"  "}
            {props.page !== props.pages &&
                <Link
                className='button' 
                to={{
                pathname: "/nytt/steg/"+(props.page + 1),
                }}
                >Neste</Link>
            }
            <br />
            <Link
            className='button' 
             to={{
              pathname: "/journal",
            }}
            ><>&#128218;</> Journal</Link>
            <Footer />
    </div>);
}

export default Navigasjon;
