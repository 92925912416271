import React, { FunctionComponent, useContext, useState , useEffect, createRef} from 'react';
import { StateProviderContext, Hendelse } from '../../StateProvider';
import { Link } from 'react-router-dom';
import Navigasjon from './Navigasjon';
export interface Props {
  
    
  }

const Tempratur: FunctionComponent<Props> = (props) => {
  const dateRef = createRef<HTMLInputElement>();
  const {lagreDelerAvHendelse: lagreDelerAvInnlevering,enNyHendelse: nyInnlevering, hendelser: innleveringer} = useContext(StateProviderContext);

  useEffect(() => {
    if(nyInnlevering?.date && nyInnlevering?.tempreture) return;

    if(dateRef.current){
      dateRef.current.valueAsDate = new Date();
    }

    lagreDelerAvInnlevering({
      tempreture: 6,
      date: new Date().toISOString().slice(0, 10)})
  
  }, []);

    return (<div className="new-submition">
      <h4>Tempratur</h4>
            <p>
              Dato: <input ref={dateRef} type="date" name="date" value={nyInnlevering?.date || new Date().toISOString().slice(0, 10)} onChange={(e)=>{
                lagreDelerAvInnlevering({date: new Date(e.target.value).toISOString().slice(0, 10)})
              }}
            
              />
            </p>
            <label htmlFor="points">Hvordan har du det nå?</label>
            <br />
            <label>Verst</label>
            <input
              type="range"
              id="points"
              value={nyInnlevering?.tempreture || 5}
              onChange={(e) =>{
                lagreDelerAvInnlevering({tempreture: e.target.value})
              }}
              name="tempreture"
              min="0"
              max="10"
            />
            <label>Best</label>
           <Navigasjon page={1} pages={6} />
    </div>);
}

export default Tempratur;