import { FunctionComponent} from 'react';
import { Link } from 'react-router-dom';
const OmKAT: FunctionComponent = () => {
    return (<div>
    <article>
        <section className='splash'>
            <section>
            <h2>Hva er Kognitiv Terapi?</h2>
            <p>Kognitiv atferdsterapi (KAT) er en populær og etterspurt terapiform. KAT retter seg mot problemløsning og innsikt i sammenhengen mellom hva vi tenker, hvordan vi tolker tankene og hvordan dette igjen påvirker kroppslige reaksjoner og atferd. Kognitiv er det som har med erkjennelse, oppfatning og tenkning å gjøre.</p>
            <p>KAT fokuserer på:
                <ul>
                    <li>Å forklare at våre følelser kommer fra tanker</li>
                    <li>Å lære å identifisere våre tanker</li>
                    <li>Å forholde seg til tanker som er problematiske eller urealistiske</li>
                    <li>Å lære å forandre/endre problematiske eller adferd for å redusere lidelse</li>
                </ul>
            </p>
            <p>Å lære å forandre/endre problematiske eller adferd for å redusere lidelse</p>
            <p>KAT er hjelp til selvhjelp, du lærer å bli din egen terapeut. Når du har det dårlig – så er det nesten alltid negative tanker til stede. Man føler hva man tenker. Analyserer du tankene henger de ofte sammen med dårlige følelser (deprimert, sint, trist, irritert og redd). Du kan spørre om disse negative tankene er de realistiske? Hjelper de? Er de velbegrunnede?</p>
            <h2>Hvorfor tenker vi som vi gjør?</h2>
            <ul>
                <li>Tidligere erfaringer har lært oss å tenke som vi gjør!</li>
                <li>Vi kan lære på nytt og endre hva vi har lært tidligere, og på denne måte kan vi endre urealistiske tanker om oss selv, om andre og om fremtiden.</li>
                <li>Nye tanker må vi så øve på, - igjen og igjen.</li>
                <li>For å forandre tanker må vi vite hva vi tenker.</li>
                <li>Vi må fange tankene som påvirker følelsene for å endre dem.</li>
                <li>For å gjøre dette må vi se på sammenhengen mellom tanke og følelser.</li>
            </ul>
            <p>Automatiske tanker er tanker som bare kommer enden vi vil ha dem eller ikke (vi bestemmer ikke å ha tanken - vanetanker). De trenger ikke å være hele setninger, de kan fremstå i ord eller bilder. Vi legger ofte ikke merke til dem, vi ønsker ikke å ha dem. Situasjoner skrur dem på automatisk, ofte spesifikke for bestemte situasjoner, de er personlige – det er ikke alle som tenker på samme måte, vi tror på dem som vi tror på våre sanser, de er raske og korte, men ikke alltid riktig eller smarte. KAT er en metode for å finne frem til dine ressurser, interesser og sterke sider. Hva er du god til, og hvordan kan du bruke nettopp dette for å lette dine problemer? </p>
            <h2>Situasjon – tanke – følelse </h2>
            <p>Vi legger hele tiden merke til situasjonen vi er i og våre følelser – men ofte legger vi ikke merke til tankene som påvirker våre følelser. Det er viktig at vi blir oppmerksomme på tankene for å kunne forandre dem. Tankene kan forandres. Det er ikke situasjonen som skaper en følelse, men hva man tenker om denne.</p>
            <p>Viktig forberedelse for innsikt og bedring er å kunne skille mellom situasjon, tanker og følelser. Vi blander ofte sammen tanker og følelser. Hvis vi bruker hele setninger så er vi vanligvis i ferd med å beskrive våre tanker. Grunnleggende følelser kan være sinne, frykt, avsky, sorg og glede. For å finne ut hvor sterke en følelse er, så kan det være greit å bruke en skala.</p>
            <h2>Tankeskjema</h2>
            <p>Vi blir ikke fortvilet eller urolige bare på grunn av hendelser i seg selv, men ut fra de tankene vi gjør oss om hendelsene. Endringer i tankene kan føre til endringer i følelsene. For å oppnå dette er det ofte nyttig med systematisk trening. Denne jobben kan ta utgangspunkt i tankeskjemaet. Tankeskjema er en viktig metode for å endre negative automatiske tanker. Legg merke til egne følelser daglig. Legg merke til automatiske tanker og se om vi kan tenke på en annen måte eller se en annen side av situasjonen. Eksempel:</p>
            <p><b>Situasjon/Hendelse:</b> Jeg skal opp til eksamen. </p>
            <p><b>Tankene:</b> Jeg kommer til å stryke. Folk kommer til å tro jeg er dum og ta avstand fra meg. </p>
            <p><b>Følelsene:</b> Opplevelse av sterk angst og uro, fortvilet, stresset.</p>
            <p><b>Annen måte å tenke på:</b> Hvorfor er jeg så sikker på at jeg vil stryke? Og er det riktig at man er dum, fordi om man stryker? Jeg kjenner da flere som har strøket, og jeg synes ikke de er dumme. Det er irriterende å stryke, men ikke forferdelig.</p>
            <p>Hensikten med tankeskjema er å gi deg verktøy som kan hjelpe deg med å håndtere problemer og oppnå dine ønsker også når du er ferdig med behandlingen.</p>
            
            <h2>Tankene</h2>
            <p>Dersom du tenker etter, kan det være du legger merke til at du stadig fører en indre samtale med deg selv. Du tenker over og ser for deg ting du har opplevd, kommenterer dine handlinger og forestiller deg hva som kan komme til å skje. Vanligvis skjer dette uten at du selv helt er klar over det. Hvilken indre samtale fører du for eksempel akkurat nå mens du leser dette?</p>
            <p>Måten vi tenker omkring en hendelse på, vil påvirke hvilke følelser som blir knyttet til denne hendelsen. Mennesker som sliter med angst eller depresjon, har ofte en strøm av ubehagelige tanker. De negative tankene virker troverdige, og de kan være bygget på virkelige hendelser, men er ofte overdrevne eller ugyldige. Ulike følelser er knyttet til bestemte tankemønstre. Angst eller frykt er forbundet med tanker om trussel eller fare. Depressive følelser er forbundet med tanker om feil eller tap, verdiløshet, meningsløshet eller håpløshet. </p>
            <p>Men det finnes også støttende tanker som vi har med oss og som kan hjelpe oss å hente fram det beste i oss.</p>
            <p>Sett at du er hjemme alene en mørk og stormfull kveld. Plutselig hører du et brak fra naborommet! Hvis du tenker at det er en innbruddstyv som forårsaker bråket, vil du sannsynligvis bli redd. Hvis du isteden tenker at det var et vindu som blåste igjen, vil du nok føle noe annet. </p>
            <p>Et annet eksempel på tankenes betydning: Hvis du står i en kø og personen foran deg tråkker deg hardt og smertefullt på tærne, er det ikke rart at du blir irritert. Dersom det viser seg at personen har en hvit spaserstokk i hånden, som tegn på at hun er blind, er det trolig at du ikke bare vil tenke annerledes om det som har skjedd. Du vil sikkert også oppleve at følelsene dine brått vil endre seg – kanskje blir du flau over at du ble sint i utgangspunktet. Uansett om det fortsatt gjør vondt i foten, har dine tanker om situasjonen endret seg og dermed også dine følelser.</p>
            </section>
        </section>
    </article>
    
     {" "}
    <Link className='button' to={{pathname: "/"}} >Tilbake</Link>
</div>);};

export default OmKAT;