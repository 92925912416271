import React, { FunctionComponent, useContext, useState } from 'react';
import { useNavigate } from "react-router-dom";
const Footer: FunctionComponent = (props) => {
  const navigate = useNavigate();
    return (<footer>
              <p>Prosjektet er laget av frivillige. </p>
              <a href="/tilbakemeldinger" >Si din mening ved å trykke her.</a>
            </footer>);
            }

export default Footer;
