import React, { FunctionComponent, useContext, useState , useEffect, createRef} from 'react';
import { StateProviderContext } from '../../StateProvider';
import { Link } from 'react-router-dom';
import Navigasjon from './Navigasjon';
export interface Props {
    
  }

const AlternativTanke: FunctionComponent<Props> = (props) => {
  const dateRef = createRef<HTMLInputElement>();
  const {lagreDelerAvHendelse: lagreDelerAvInnlevering, enNyHendelse: enHendelse} = useContext(StateProviderContext);

  useEffect(() => {
    if(dateRef.current){
      dateRef.current.valueAsDate = new Date();
    }

  }, []);
  const hentTankefeller = (tankefeller:string[] | undefined) => {
    if(!tankefeller) return "";
    return tankefeller.map((enTankefelle:string) => {
      return <span className='alternativTanke enTankefelle'>{enTankefelle}</span>;
    });
  };

    return (<div className="new-submition">
          <h4>
              Hvordan kan du utfordre tankene dine? Hva er en annen måte å tenke
              på? <em>Prøv å tenk <u>faktabasert, uten tankefeller.</u></em>
            </h4>
            <p>{hentTankefeller(enHendelse?.bias?.filter(b => b.checked).map(m => m.norsk))}</p>

            <p><em>"Hvorfor er jeg så sikker på at jeg vil stryke? Og er det riktig at man er dum, fordi om man stryker? Jeg kjenner da flere som har strøket, og jeg synes ikke de er dumme. Det er irriterende å stryke, men ikke forferdelig."</em></p>
            
          
            <textarea value={enHendelse?.alternative} rows={12} cols={30} name="alternative" onChange={(e)=>{
              lagreDelerAvInnlevering({alternative:e.target.value})
            }}></textarea>
            <Navigasjon page={6} pages={6} />
    </div>);
}

export default AlternativTanke;