import React, { Fragment, FunctionComponent, useContext, useState } from 'react';
import { StateProviderContext, Hendelse } from '../StateProvider';
import { Link } from 'react-router-dom';
import Footer from '../Footer';

export interface Props {
    
  }


const Hendelser: FunctionComponent<Props> = (props) => {

  const {hendelser} = useContext(StateProviderContext);
  const [filterOrd, setFilterOrd] = useState("");
  const sammenligneDato = (datoA:string, datoB:string) => new Date(datoB).getTime() - new Date(datoA).getTime();
  const påDatoEllerID = (a:Hendelse,b:Hendelse) => sammenligneDato(a.date!,b.date!) || b.id - a.id
  const filterInnleveringer = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilterOrd(e.target.value);
  }
  return (
    <div className='board'>
      <h1>Journal</h1>
      {hendelser.length === 0 && <h4>Journalen er tom.</h4>}
  
      <div className='søkwrapper'>        
        {hendelser.length > 2 &&
          <input className='søkefelt' type="text" placeholder='Søk' onChange={filterInnleveringer} />
        }
        <a  className="button innsikt" href="./innsikt"><>🌦</> Innsikt</a>
        <Link className='button' to={{
          pathname: "/nytt/steg/1",
        }} ><>&#9997;</> Ny</Link>
      </div>
      {hendelser
      .sort(påDatoEllerID)
      .filter((hendelse) => hendelse.event?.toLocaleLowerCase().includes(filterOrd.toLocaleLowerCase()) || hendelse.date?.includes(filterOrd) )
      .map((hendelse,index) => {
        return (<a  key={index} className='hendelseLink' href={"/vis/"+hendelse.id}><p> {Number(hendelse.tempreture) > 4 ? <>😍</>:  <>😔</>}  {hendelse.date} - {hendelse.event}</p></a>
      )})}
      <Footer />
    </div>);
}

export default Hendelser;
