import { FunctionComponent} from 'react';
import Footer from "../Footer"
import { Link } from 'react-router-dom';
const GDPR: FunctionComponent = () => {
    return (<div>
    <article>
        <section className='splash'>
            <section>
            <h2>Data om deg</h2>
            <p>Vi bruker ikke informasjonskapsler. Og samler på ingenting.</p>
            <p>Når det gjelder lokal nettlagring, lagres nettapplikasjonen data kun lokalt i brukerens nettleser.
I motsetning til informasjonskapsler er lagringsgrensen langt sikrere og informasjon blir aldri lagret eller overført til noen andre enn deg.
Nettlagring er per opprinnelse (per domene og protokoll). Alle sider, fra en opprinnelse, kan lagre og få tilgang til de samme dataene. Dataene dine finner du på nettleser innstillinger.</p>
<p>Ingen andre enn deg kan lese det du lagrer <em>her</em>. Data lagres kun lokalt på din nettleser og ikke andre steder.</p>
        </section>
        </section>
    </article>
    
     {" "}
    <Link className='button' to={{pathname: "/"}} >Tilbake</Link>
    <Footer />
</div>);};

export default GDPR;