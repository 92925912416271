import React, { FunctionComponent, useContext, useState , useEffect, createRef} from 'react';
import { StateProviderContext } from '../../StateProvider';
import { Link } from 'react-router-dom';
import Navigasjon from './Navigasjon';
export interface Props {
    
  }

const Atferd: FunctionComponent<Props> = (props) => {
  const dateRef = createRef<HTMLInputElement>();
  const {lagreDelerAvHendelse: lagreDelerAvInnlevering,enNyHendelse: nyInnlevering}= useContext(StateProviderContext);
  useEffect(() => {
    if(dateRef.current){
      dateRef.current.valueAsDate = new Date();
    }

  }, []);

    return (<div className="new-submition">
              <h4>Skriv litt om en situasjon hvor du følte deg opprørt eller hadde det vondt. <em>Ingenting om følelser eller tanker ennå.</em></h4>
              <p><em>"Jeg skal opp til eksamen."</em></p>
              <textarea rows={12} cols={30} onChange={(e)=>{
                lagreDelerAvInnlevering({event:e.target.value});
                }} value={nyInnlevering?.event} name="hendelse"></textarea>
              <Navigasjon page={2} pages={6} />
            </div>);
}

export default Atferd;