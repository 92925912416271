import React, { FunctionComponent, useContext, useState , useEffect, createRef, Fragment} from 'react';
import { StateProviderContext } from '../../StateProvider';
import { Link } from 'react-router-dom';
import Navigasjon from './Navigasjon';
export interface Props {
    
  }

const Tankefeller: FunctionComponent<Props> = (props) => {
  const {lagreDelerAvHendelse: lagreDelerAvInnlevering, enNyHendelse: nyInnlevering}= useContext(StateProviderContext);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    lagreDelerAvInnlevering({bias:nyInnlevering?.bias?.map(e => {
      if(e.name === event.target.name) {
        return {...e, checked: !e.checked} 
      } 
      return e;
    })})
  };
    return (<div className="new-submition">
          <h4>Kan du identifisere mulige tankefeller fra din tanke?</h4>
            <div className="biasSection">
              {nyInnlevering?.bias?.map((enBias,index) =>{
                return(<label key={index} htmlFor={enBias.name} className={"enTankefelle " + enBias.checked}>
                  <input
                  onChange={handleChange}
                  checked={enBias.checked}
                  key={index}
                  id={enBias.name}
                  type="checkbox"
                  name={enBias.name}
                  value={enBias.name}
                />
                <div className='enTankefellTekst' >
                  <label htmlFor={enBias.name}>{enBias.norsk}</label>
                  <br/>
                  <label className='tankefelleEksempel' htmlFor={enBias.name}>{'"'+enBias.eksempel+'"'}</label>
                </div>
               </label>)
              })}
            </div>
            <Navigasjon page={5} pages={6} />          
    </div>);
}

export default Tankefeller;