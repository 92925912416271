import React, { FunctionComponent, useContext, useState , useEffect, createRef} from 'react';
import { StateProviderContext } from '../../StateProvider';
import { Link } from 'react-router-dom';
import Navigasjon from './Navigasjon';
export interface Props {
    
  }

const Utanke: FunctionComponent<Props> = (props) => {
  const dateRef = createRef<HTMLInputElement>();
  const {lagreDelerAvHendelse: lagreDelerAvInnlevering,enNyHendelse: nyInnlevering}= useContext(StateProviderContext);

  useEffect(() => {
    if(dateRef.current){
      dateRef.current.valueAsDate = new Date();
    }

  }, []);

    return (<div className="new-submition">
          <h4>Hvilken tanke hadde du?</h4>
          <p><em>"Jeg kommer til å stryke. Folk kommer til å tro jeg er dum og ta avstand fra meg."</em></p>
            <textarea value={nyInnlevering?.thoughts} rows={12} cols={30} name="thoughts" onChange={(e)=>{
               lagreDelerAvInnlevering({thoughts:e.target.value})
            }}></textarea>
        
            <div className="nyInnleveringButtonsWrapper">
           <Navigasjon page={4} pages={6} />
            
          </div>
    </div>);
}

export default Utanke;