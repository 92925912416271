import Footer from '../Footer';
import React, { FunctionComponent, useContext, useState } from 'react';
import { StateProviderContext, Hendelse } from '../StateProvider';
import { Link } from 'react-router-dom';

export interface Props {
}

const Hjem: FunctionComponent<Props> = (props) => {
    const {hendelser} = useContext(StateProviderContext);
    return (<div>
    <article>
        <section className='splash'>
            <section>
                <p>Jeg tenker er en gratis tjeneste som tilbyr et kognitiv atferdsterapi tankeskjema med hensyn til personvern. <a href="./Hva-er-KAT">Trykk her for å lese mer.</a></p>
                <p>Vi lagrer ingenting hos oss. Du trenger heller ikke å registrere deg for å bruke tjenesten. <a href="./GDPR">Trykk her for å lese mer.</a></p>
                <p><em>Denne tjenesten fungerer på din nettleser selvom du ikke er tilkoblet på nett.</em></p>
            </section>
            <div className='image_mind_cbt'> </div>
        </section>
    </article>
    
     {" "}
    <Link className='button'  to={{
        pathname: "/nytt/steg/1",
      }} ><>&#9997;</> Ny</Link>
      {" "}
      {hendelser.length > 0 &&
        <Link className='button' to={{
            pathname: "/journal",
        }} ><>&#128218;</> Journal</Link>
        }
      <Footer />       
       </div>);
};

export default Hjem;
