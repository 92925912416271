import React, { FunctionComponent, useContext, useState } from 'react';
import { StateProviderContext} from '../StateProvider';
import { Link, useParams } from 'react-router-dom';
import Footer from  "../Footer"

export interface Props {}
const EnHendelse: FunctionComponent<Props> = (props) => {

  const {hendelser, slettHendelse} = useContext(StateProviderContext);
  let { id } = useParams();
  if(!id){
    return (<h4>Ingen hendelse funnet med id {id} lagret på din enhet.</h4>);
  }
  const enHendelse = hendelser.find((currHendense) => currHendense.id == Number(id));
  if(!enHendelse){
      return (<h4>Ingen hendelse funnet med id {id} lagret på din enhet.</h4>);
  }
  const hentTankefeller = (tankefeller:string[] | undefined) => {
    if(!tankefeller) return "";
    return tankefeller.map((enTankefelle:string) => {
      return <span className='enTankefelle'>{enTankefelle}</span>;
    });
  };

    return (
    <div className='board'>
      <h1>{enHendelse.date}</h1>
      {<div className="entryView">
      <a href="#" className='slettHendelse' onClick={(e) =>{ 
            const svar = confirm("Slette '" + enHendelse.event + "' ?");
            if(svar){
              slettHendelse(id);
              window.location.href = "/journal";
            }
            }}><>&#10060;</> Slett</a>
          <p><b>Tempratur:</b> {enHendelse.tempreture} / 10</p>
          <p><b>Hendelse:</b> {enHendelse.event}</p>
          <p><b>Følelser:</b> {enHendelse.feelings}</p>
          <p><b>Tanke:</b> {enHendelse.thoughts}</p>
          <p><b>Tankefeller:</b> {hentTankefeller(enHendelse?.bias?.filter(b => b.checked).map(m => m.norsk))}</p>
          <p><b>Alternativ faktabasert tanke uten tankefeller:</b> {enHendelse.alternative}</p>
       </div>
      }
      {hendelser.length === 0 && <h4>Ingen hendelser</h4>}

      
      <Link className='button'  to={{
        pathname: "/nytt/steg/1",
      }} ><>&#9997;</> Ny</Link>
      {" "}
      
      <Link className='button' to={{
            pathname: "/journal",
        }} ><>&#128218;</> Journal</Link>
    <Footer />
    </div>);


}

export default EnHendelse;