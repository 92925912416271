import Hendelser from "./komponenter/Hendelser";
import StateProvider from "./StateProvider";

import React, { FunctionComponent, useContext, useState } from 'react';
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link
  } from "react-router-dom";
import Hjem from "./komponenter/Hjem";
import Affirmations from "./komponenter/Affirmations";
import Tempratur from "./komponenter/veiviser/tempratur";
import Følelser from "./komponenter/veiviser/følelser";
import Utanke from "./komponenter/veiviser/utanke";
import Tankefeller from "./komponenter/veiviser/tankefeller";
import Atferd from "./komponenter/veiviser/atferd";
import AlternativTanke from "./komponenter/veiviser/alternativTanke";
import EnHendelse from "./komponenter/EnHendelse";
import "./styles.css";
import Innsikt from "./komponenter/Innsikt"
import Header from "./Header";
import OmKAT from "./komponenter/OmHvaErKAT";
import GDPR from "./komponenter/GDPR";
import Admin from "./komponenter/Admin";
import SideIkkeFunnet from "./komponenter/SideIkkeFunnet";
import Tilbakemeldinger from "./komponenter/Tilbakemeldinger";

const App: FunctionComponent = (props) => {
        return (
            <StateProvider>
                <Header />
                <Router >
                    <Routes >
                        <Route path="/" element={<Hjem />}></Route>
                        <Route path="/vis/:id" element={<EnHendelse />}/>
                        <Route path="/Hva-er-KAT" element={<OmKAT />} />
                        <Route path="/GDPR" element={<GDPR />} />
                        <Route path="/nytt/steg/1" element={<Tempratur />}></Route>
                        <Route path="/nytt/steg/2" element={<Atferd />}></Route>
                        <Route path="/nytt/steg/3" element={<Følelser />}></Route>
                        <Route path="/nytt/steg/4" element={<Utanke />}></Route>
                        <Route path="/nytt/steg/5" element={<Tankefeller />}></Route>
                        <Route path="/nytt/steg/6" element={<AlternativTanke />}></Route>
                        <Route path="/journal" element={<Hendelser />}/>
                        <Route path="/admin" element={<Admin />}/>
                        <Route path="/innsikt" element={<Innsikt />}/>
                        <Route path="/tilbakemeldinger" element={<Tilbakemeldinger />}/>
                        <Route path="*" element={<SideIkkeFunnet />}/>
                        <Route path="/affirmations" element={<Affirmations />}/>
                    </Routes>
                </Router>
                
            </StateProvider>
        )
}

export default App;