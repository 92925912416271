import Footer from '../Footer';
import React, { FunctionComponent, useContext, useState } from 'react';
import { StateProviderContext, Hendelse } from '../StateProvider';
import { Link } from 'react-router-dom';
import { calculateTfIdf } from 'ts-tfidf'
import { TagCloud } from 'react-tagcloud';
import stopwords from 'stopwords-no'; 
import _ from "underscore"

export interface Props {
}

export interface TagCloudObject {

}
const Innsikt: FunctionComponent<Props> = (props) => {
    const {hendelser} = useContext(StateProviderContext);

    const eventsTextPositive:string[] = hendelser.filter(h => Number(h.tempreture) >= 5).map(h => h.event!!);
    const eventsTextNegative:string[] = hendelser.filter(h => Number(h.tempreture) < 5).map(h => h.event!!);
    //const texts = ['This is the first document.', 'This document is the second document.', 'And this is the third one.', 'Is this the first document?']
    const positivtResult = calculateTfIdf({texts: eventsTextPositive})
    const positivtFiltered = positivtResult.map(ma => [...ma].filter(el => el[1] > 0 && !stopwords.includes(el[0])))
    const positivtOrdListe = positivtFiltered.filter(el => el.sort(ele => ele[1]).reverse())
    const pos =  positivtOrdListe.flatMap(el => el).flatMap(el => el[0])
    var tagCloudDataPos = _.map(_.countBy(pos), function(value, key){
      return {
          value: key,
          count: value
      };
  });
    console.log(tagCloudDataPos)
   //const postivteOrd = pos.map(innerEl => <p>{innerEl.value}</p>)
    console.log("positivt", pos)
    // NEG
    const negativResult = calculateTfIdf({texts: eventsTextNegative})
    const negativResultFiltered = negativResult.map(ma => [...ma].filter(el => el[1] > 0 && !stopwords.includes(el[0])))
    const negativOrdListe = negativResultFiltered.filter(el => el.sort(ele => ele[1]).reverse())
    const neg =  negativOrdListe.flatMap(el => el).flatMap(el => el[0])
    var tagCloudDataNeg = _.map(_.countBy(neg), function(value, key){
      return {
          value: key,
          count: value
      };
  });
    console.log('negativ', neg)
 
    return (<div>
    <div >
      <h1>Innsikt</h1>
      {hendelser.length <= 3 ?
        <p>Kom tilbake her når du har skrevet mer i journalen</p>
          :
          <>
            <div style={{padding:"2rem"}}>
            <h1>Fine situasjoner</h1>
            <TagCloud
              minSize={12}
              maxSize={35}
              tags={tagCloudDataPos}
            />
            </div>
            <div style={{padding:"2rem"}}>
              <h1>Mindre fine situasjoner</h1>
              <TagCloud
              minSize={12}
              maxSize={35}
              tags={tagCloudDataNeg}
            />
            </div>
            </>}
   
        
    </div>
    
     {" "}
    <Link className='button'  to={{
        pathname: "/nytt/steg/1",
      }} ><>&#9997;</> Ny</Link>
      {" "}
      {hendelser.length > 0 &&
        <Link className='button' to={{
            pathname: "/journal",
        }} ><>&#128218;</> Journal</Link>
        }
      <Footer />       
       </div>);
};

export default Innsikt;
