import Footer from '../Footer';
import React, { FunctionComponent, useContext, useState } from 'react';
import { StateProviderContext, Hendelse } from '../StateProvider';
import { Link } from 'react-router-dom';

export interface Props {
}

const Tilbakemeldinger: FunctionComponent<Props> = (props) => {
    const {hendelser} = useContext(StateProviderContext);
    return (<div>
    <article>
        <section className='splash'>
            <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSeoSWm7zAXPWxpIN37KWhZczyitgYivhgb8wD16xZiIjeyRTQ/viewform?embedded=true" width="610" height="694">Laster....</iframe>
        </section>
    </article>
    
     {" "}
    <Link className='button'  to={{
        pathname: "/nytt/steg/1",
      }} ><>&#9997;</> Ny</Link>
      {" "}
      {hendelser.length > 0 &&
        <Link className='button' to={{
            pathname: "/journal",
        }} ><>&#128218;</> Journal</Link>
        }
      <Footer />       
       </div>);
};

export default Tilbakemeldinger;
