import Footer from '../Footer';
import React, { FunctionComponent, useEffect, useState } from 'react';

export interface Props {
}

const Affirmations: FunctionComponent<Props> = (props) => {
    const affirationsList = [
        "I am worthless",
        "My interests are valid, and I am allowed to be excited and passionate about them even if other people aren't.",
        "People disagreeing with me does not mean my lived experience or my opinion are invalid.",
        "I a m allowed to ask for reassurance and I am worthy of people's time and attention.",
        "I am allowed to ask questions and require certain-pty in order to feel safety and control.",
        "Some people won't like me no matter what and that is not a reflection of how likeable I am. ",
        "I decide what thoughts to reject and let pass.",
        "I am no good",
        "I deserve nothing",
        "I am disabled" ,
        "I have the right to say no to things/situations that feel not comfortable to me",
        "I have the right to make my own mistakes",
        "I'm a dollar who must be liked by everyone!",
        "REMOVE YOURSELF from places where you are disrespected and not valued",
        "I’m aware my past experiences are bad and damaging. I’m now a stronger person and know my self better.",
        "When people disagree with me, it does not meean they don't like me or disrespect me",
        "I should not do what brings me peace"];

        useEffect(()=>{

            document.addEventListener('touchstart', e => {
            touchstartY = e.changedTouches[0].screenY
            })
    
            document.addEventListener('touchend', e => {
                touchendY = e.changedTouches[0].screenY
                checkDirection()
            })
            
            document.addEventListener('mousedown', (evet) => touchstartY = evet.y);
            document.addEventListener('mousemove', (evet) => touchendY = evet.y);
            document.addEventListener('mouseup', () => checkDirection())

        },
        [])
    
    let [current, setCurrent] = useState(0)
    console.log(current)
    let [pn, setPN] = useState("")

    let touchstartY = 0
    let touchendY = 0
    
    const checkDirection =() => {
       if(touchendY < touchstartY) setPN("negative");
      if (touchendY > touchstartY) setPN("positive");
      
      setTimeout(() =>{
        setCurrent(Math.floor(Math.random()* affirationsList.length)) 
        setPN("normal")
      },1000)
    }
    
return (<div>
    <section className={'affirmation ' + pn}>
                    {affirationsList[current]}
            </section>
      <Footer />       
       </div>);
};

export default Affirmations;
