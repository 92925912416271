import React, { createContext, useEffect, useState } from 'react';

const biasList:IBias[] = [{id:0, name:"allOrNothing", norsk:"Alt-eller-ingenting", checked:false,eksempel:"Jeg er en total fiasko"},
{id:1,name:"catastrophizing", norsk:"Katastrofal", checked:false, eksempel:"Hva om det verste skjer"},
{id:2,name:"emotionalReasoning", norsk:"Emosjonelle resonnementer", checked:false,eksempel:"Jeg føler det slik, så det må være sant"},
{id:3, name:"magnificatioOfNegative",norsk:"Forstørrelse av negativt", checked:false, eksempel:"Jeg ødela alt totalt" },
{id:4,name:"minimizationOfThePositive", norsk:"Minimering av det positive", checked:false,eksempel:"De mente egentlig ikke det"},
{id:5,name:"jumpingToConclusions", norsk:"Hoppe til konklusjoner", checked:false,eksempel:"Hun sa ikke hei, så hun hater meg"},
{id:6,name:"fortuneTelling", norsk:"Spådom", checked:false,eksempel:"Jeg kommer til å stryke på eksamen"},
{id:7,name:"mindReading", norsk:"Tankelesing", checked:false,eksempel:"Han vil ikke snakke med meg"},
{id:8,name:"selfBlaming", norsk:"Selvbeskyldning", checked:false,eksempel:"Det er min feil"},
{id:8,name:"otherBlaming", norsk:"Skylde på andre", checked:false,eksempel:"Dette er deres feil"},
{id:9,name:"filterOutPositive", norsk:"Filtrere bort positivt", checked:false,eksempel:"Ingenting bra skjedde i dag"},
{id:10,name:"overgeneralizing", norsk:"Overgeneralisering", checked:false,eksempel:"Alle misliker meg"},
{id:11,name:"shouldMustStatements", norsk:"Burde/ må uttalelser", checked:false,eksempel:"Burde ha gjort dette"}]

export interface IBias {
  id:number, name:string, norsk:string, checked:boolean, eksempel:string
}
export interface Hendelse{
    id:number
    event?:string,
    date?: string,
    tempreture?:string,
    feelings?:string,
    bias:IBias[],
    thoughts?:string,
    alternative?:string
}
export interface State {
    enNyHendelse: Hendelse | undefined,
    hendelser:Hendelse[]
    slettHendelse:Function,
    lagreHendelse:Function,
    lagreDelerAvHendelse:Function,
    slettAlleHendelser: Function,
    hentDatabaseData:Function
}

export const StateProviderContext = createContext<State>({hentDatabaseData: ()=>{},hendelser:[],slettAlleHendelser:() => {}, slettHendelse:() =>{}, lagreDelerAvHendelse:()=>{}, lagreHendelse:()=>{}, enNyHendelse:undefined});
const StateProvider = (props: any) => {
    const [hendelser, setHendelser] = useState<Hendelse[]>([]);
    const [enNyHendelse, setEnNyHendelse] = useState<Hendelse>({id:0,bias:biasList});
    useEffect(() => {
        if (typeof Storage !== "undefined") {
          try{
            const data = localStorage.getItem("data");
            if (data) {
              const jsonData = JSON.parse(data);
              setHendelser(jsonData);
            }
          }catch(e){
            localStorage.setItem("data","");
            const data = localStorage.getItem("data");
            if (data) {
              const jsonData = JSON.parse(data);
              setHendelser(jsonData);
              console.log("FIXED ERROR: ", e);
            }
          }         
       
          } else {
            alert("Vi støtter ikke lokal lagring av data på din enhet.");
          }
    }, []);
   const slettHendelse = (id:number) =>{
      hendelser.splice(hendelser.findIndex((submition) => submition.id == id),1)
      localStorage.setItem("data", JSON.stringify(hendelser));
      setHendelser([...hendelser]);
    }

    const lagreDelerAvHendelse = (submtionPartial:Partial<Hendelse>) =>{
      const newStateOfSubmition:Hendelse = Object.assign({}, enNyHendelse, submtionPartial) 
      setEnNyHendelse(newStateOfSubmition);
    }
    const hentDatabaseData = () =>{
      return localStorage.getItem("data");
    }
    const slettAlleHendelser = () =>{
      setHendelser([])
      localStorage.setItem("data", "");
    }
    const lagreHendelse = () =>{
      if(!enNyHendelse) return;
      let id = 0;
      if(hendelser.length > 0 && (enNyHendelse.id == 0 || enNyHendelse.id == null || enNyHendelse.id == undefined)){
        id = Math.max(...hendelser.map((innle) => innle.id)) + 1;
      }
      hendelser.push({...enNyHendelse,id:id})
      localStorage.setItem("data", JSON.stringify(hendelser));
      setHendelser([...hendelser]);
      setEnNyHendelse({id:(id+1),bias:biasList});
    }

    return <StateProviderContext.Provider value={{hentDatabaseData: hentDatabaseData,hendelser:hendelser,slettAlleHendelser:slettAlleHendelser, slettHendelse:slettHendelse, lagreDelerAvHendelse:lagreDelerAvHendelse, lagreHendelse:lagreHendelse, enNyHendelse:enNyHendelse}} >{props.children}</StateProviderContext.Provider>;
};
export default StateProvider;